<h3 class="white">{{ 'dashboard.header.documents' | translate }}</h3>
<div
    *ngFor="let document of documents | slice: 0:resultsToDisplayPerCategory"
    class="document-container"
    (click)="downloadDocument(document)">
    <shared-table-icon-cell
        icon="file-text"
        [isRowIcon]="true"
        [isOutlined]="true">
    </shared-table-icon-cell>
    <div class="document-name">{{ document.displayedName }}</div>
    <div class="document-domain">
        {{ 'mystobag-document-shared.domain.' + document.domain | translate }}
    </div>
    <div class="document-type">
        {{ 'mystobag-document-shared.type.' + document.type | translate }}
    </div>
    <div class="document-updated">{{ document.updated | date }}</div>
    <div class="document-action">
        <button *ngIf="document.contentType === 'FILE'" mat-icon-button>
            <shared-icon icon="download"></shared-icon>
        </button>

        <button *ngIf="document.contentType === 'VIDEO'" mat-icon-button>
            <shared-icon
                icon="video"></shared-icon>
        </button>
    </div>
</div>

<app-show-more-button
    *ngIf="displayShowMoreButton"
    [ngPlural]="remainingItemCount"
    (click)="visitDocumentList()">
    <ng-template ngPluralCase="=1">{{
        'dashboard.showMoreDocumentsSingular' | translate
    }}</ng-template>
    <ng-template ngPluralCase="other">
        {{ 'dashboard.showMoreDocumentsPlural' | translate: { remaining: remainingItemCount } }}
    </ng-template>
</app-show-more-button>
