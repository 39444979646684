<ng-container *ngIf="orderItem$ | async as orderItem">
    <div fxLayout="column" fxLayout.gt-xs="row" class="detail-wrapper">
        <div fxLayout="column" class="image-container">
            <img
                class="image"
                *ngIf="getProductImage$(orderItem) | async as productImage; else placeholder"
                [src]="productImage" />
            <ng-template #placeholder>
                <shared-placeholder></shared-placeholder>
            </ng-template>
        </div>
        <div fxLayout="column" class="detail">
            <div fxLayout="row" class="name">
                {{ orderItem.name }}
            </div>
            <div fxLayout="row" *ngIf="equipment.serialNumber">
                <div class="label-bold">
                    {{ 'equipment-list.serialNumber' | translate }}
                </div>
                <div>{{ equipment.serialNumber }}</div>
            </div>
            <div fxLayout="row" *ngIf="orderItem.hasDetails">
                <div class="label-bold">
                    {{ 'equipment-list.commission' | translate }}
                </div>
                <div>{{ equipment.relatedOrder.nameOfOrderer }}</div>
            </div>
            <div fxLayout="row" *ngIf="orderItem.hasDetails">
                <div class="label-bold">
                    {{ 'equipment-list.orderDate' | translate }}
                </div>
                <div>{{ equipment.relatedOrder.orderDate | date }}</div>
            </div>
            <div fxLayout="row">
                <div class="label-bold">
                    {{ 'equipment-list.deliveryDate' | translate }}
                </div>
                <div>{{ equipment.relatedOrder.plannedDeliveryDate | date }}</div>
            </div>
        </div>
    </div>
    <div fxLayout="column" fxLayout.gt-xs="row wrap" class="control">
        <div fxLayout="row wrap" fxLayout.gt-xs="row wrap" fxLayoutAlign="start">
            <order-fabric-reorder-button
                [visible]="shouldDisplayFabricReorderButton(orderItem)"
                [orderNumber]="equipment.relatedOrder.id"
                [position]="orderItem.position"
                (configuredFabric)="openBasketSelect($event)"></order-fabric-reorder-button>
            <ng-container
                *ngIf="
                    shouldDisplaySparePartList(
                        orderItem,
                        equipment.relatedOrder.plannedDeliveryDate
                    )
                ">
                <button
                    class="reorder-button"
                    mat-button
                    (click)="
                        navigateToSparePartReorder(
                            equipment.relatedOrder.id,
                            orderItem,
                            equipment.serialNumber
                        )
                    "
                    *ngxPermissionsOnly="['ACCESS_SERVICE_BASKET']">
                    <shared-icon icon="playlist-add"></shared-icon>
                    {{ 'equipment-list.reorderSpareParts' | translate }}
                </button>
            </ng-container>
            <ng-container *ngIf="!orderItem.cancelled">
                <button
                    *ngxPermissionsOnly="['ACCESS_SERVICE_SERVICEREPORT']"
                    class="service-report-button"
                    mat-button
                    (click)="createServiceReport(equipment.serialNumber)">
                    <shared-icon icon="tool"></shared-icon>
                    {{ 'equipment-list.createServiceReport' | translate }}
                </button>
            </ng-container>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center" *ngIf="orderItem.hasDetails">
            <button
                *ngxPermissionsOnly="['ACCESS_SERVICE_ORDER']"
                class="open-order"
                mat-flat-button
                (click)="visitOrder(equipment.relatedOrder.id)">
                {{ 'equipment-list.openOrder' | translate }}
            </button>
        </div>
    </div>
</ng-container>
