import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Phase, ProductDTO } from '@stobag/mystobag-catalog-shared';

@Component({
    selector: 'app-product-result-card',
    templateUrl: './product-result-card.component.html',
    styleUrls: ['./product-result-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductResultCardComponent {
    Phase = Phase;

    @Input() product: ProductDTO;
}
