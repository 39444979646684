<div class="search-result-container" data-cy="search-result" #searchResultContainer>
    <ng-container *ngIf="searchResponse">
        <app-product-results
            *ngIf="searchResponse.products?.length > 0"
            data-cy="product-result-container"
            [products]="searchResponse.products"
            [searchKeyword]="searchResponse.keyword">
        </app-product-results>
        <app-spare-part-results
            *ngIf="searchResponse.sparePartGroups?.length > 0"
            data-cy="spare-part-result-container"
            [sparePartGroups]="searchResponse.sparePartGroups"
            [searchKeyword]="searchResponse.keyword">
        </app-spare-part-results>
        <app-basket-results
            *ngIf="searchResponse.baskets?.content.length > 0"
            data-cy="basket-result-container"
            [baskets]="searchResponse.baskets"
            [searchKeyword]="searchResponse.keyword">
        </app-basket-results>
        <app-offer-results
            *ngIf="searchResponse.offers?.length > 0"
            data-cy="offer-result-container"
            [offers]="searchResponse.offers"
            [searchKeyword]="searchResponse.keyword">
        </app-offer-results>
        <app-order-results
            *ngIf="searchResponse.orders?.length > 0"
            data-cy="order-result-container"
            [orders]="searchResponse.orders"
            [searchKeyword]="searchResponse.keyword">
        </app-order-results>
        <app-service-report-results
            *ngIf="searchResponse.serviceReports?.length > 0"
            data-cy="service-report-result-container"
            [serviceReports]="searchResponse.serviceReports"
            [searchKeyword]="searchResponse.keyword">
        </app-service-report-results>
        <app-document-results
            *ngIf="searchResponse.documents?.content?.length > 0"
            data-cy="document-result-container"
            [documents]="searchResponse.documents.content"
            [searchKeyword]="searchResponse.keyword">
        </app-document-results>
        <app-equipment-results
            *ngIf="searchResponse.equipments?.length > 0"
            data-cy="equipment-result-container"
            [equipments]="searchResponse.equipments">
        </app-equipment-results>
        <div *ngIf="searchResponse.searchInProgress" class="search-loading">
            <h3>{{ 'dashboard.stillLoading' | translate }}</h3>
            <div ngxUiLoaderBlurred loaderId="search-in-progress" [blur]="0">
                <ngx-ui-loader
                    loaderId="search-in-progress"
                    fgsColor="white"
                    [hasProgressBar]="false"></ngx-ui-loader>
            </div>
        </div>
        <h3 *ngIf="searchResponse.keyword && hasNoResult(searchResponse)" class="no-results">
            {{ 'dashboard.noResults' | translate }}
        </h3>
    </ng-container>
</div>
