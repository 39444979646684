import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BasketSparePartService } from '@stobag/mystobag-basket-shared';
import { CreateSparePartConfigurationRequest } from '@stobag/mystobag-basket-shared/lib/shared/model/create-spare-part-configuration-request';
import { SparePartGroupDTO, SparePartSharedService } from '@stobag/mystobag-catalog-shared';
import { MyStobagRedirectService, serviceUrls } from '@stobag/mystobag-shared';
import { Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { resultsToDisplayPerCategory } from 'src/app/constants';

@Component({
    selector: 'app-spare-part-results',
    templateUrl: './spare-part-results.component.html',
    styleUrls: ['./spare-part-results.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SparePartResultsComponent implements OnDestroy {
    resultsToDisplayPerCategory = resultsToDisplayPerCategory;
    amounts: Record<string, FormControl> = {};
    _sparePartGroups: SparePartGroupDTO[];
    displayShowMoreButton = false;
    remainingItemCount: number;

    @Input() searchKeyword: string;

    @Input() set sparePartGroups(sparePartGroups: SparePartGroupDTO[]) {
        this._sparePartGroups = sparePartGroups;
        this.remainingItemCount = sparePartGroups.length - resultsToDisplayPerCategory;
        this.displayShowMoreButton = this.remainingItemCount > 0;
        this.amounts = sparePartGroups
            .map(sparePartGroup => ({
                [sparePartGroup.catalogNr]: new FormControl(1),
            }))
            .reduce((acc, curr) => ({ ...acc, ...curr }), {});
    }

    private subscription = new Subscription();

    constructor(
        private redirectService: MyStobagRedirectService,
        private basketSparePartService: BasketSparePartService,
        private sparePartService: SparePartSharedService,
    ) {}

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    visitSparePartList() {
        this.redirectService.navigateToService(
            `${serviceUrls.catalog}/spare-parts?search=${this.searchKeyword}`,
        );
    }

    configureWithWizard(sparePartGroup: SparePartGroupDTO) {
        this.subscription.add(
            this.sparePartService
                .openConfigureSparePartDialog(sparePartGroup)
                .pipe(
                    map(({ length, unitOfLength, color, quantity }) => ({
                        length,
                        unitOfLength,
                        color,
                        quantity,
                        catalogNr: sparePartGroup.catalogNr,
                    })),
                    switchMap((request: CreateSparePartConfigurationRequest) =>
                        this.basketSparePartService.createAndPlaceIntoBasket(
                            request,
                            sparePartGroup.unit,
                        ),
                    ),
                )
                .subscribe(),
        );
    }

    useSelector(sparePartGroup: SparePartGroupDTO) {
        this.subscription.add(
            this.sparePartService
                .openSelectSparePartDialog(sparePartGroup)
                .pipe(
                    switchMap(({ sparePart, quantity }) =>
                        this.basketSparePartService.addToBasket(sparePart, quantity),
                    ),
                )
                .subscribe(),
        );
    }

    addToBasket(sparePartGroup: SparePartGroupDTO) {
        const amount = this.amounts[sparePartGroup.catalogNr].value;
        this.subscription.add(
            this.basketSparePartService.addToBasket(sparePartGroup.members[0], amount).subscribe(),
        );
    }
}
