import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MyStobagRedirectService, ServiceReportDTO, serviceUrls } from '@stobag/mystobag-shared';
import { Subscription } from 'rxjs';
import { resultsToDisplayPerCategory } from 'src/app/constants';

@Component({
    selector: 'app-service-report-results',
    templateUrl: './service-report-results.component.html',
    styleUrls: ['./service-report-results.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceReportResultsComponent implements OnInit, OnDestroy {
    resultsToDisplayPerCategory = resultsToDisplayPerCategory;
    displayShowMoreButton = false;
    remainingItemCount: number;

    @Input() serviceReports: ServiceReportDTO[];
    @Input() searchKeyword: string;

    private subscription = new Subscription();

    constructor(private redirectService: MyStobagRedirectService) {}

    ngOnInit() {
        this.remainingItemCount = this.serviceReports.length - resultsToDisplayPerCategory;
        this.displayShowMoreButton = this.remainingItemCount > 0;
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    getIdentifier(data: any): string {
        return data.serialNumber == null || data.serialNumber.trim() === ''
            ? `${data.orderNumber}/${data.articleNumber}`
            : data.serialNumber;
    }

    visitServiceReport(serviceReport: ServiceReportDTO) {
        this.redirectService.navigateToService(`${serviceUrls.serviceReport}/${serviceReport.id}`);
    }

    visitServiceReportList() {
        this.redirectService.navigateToService(
            `${serviceUrls.serviceReport}/list?search=${this.searchKeyword}`,
        );
    }
}
