<h3 class="white">{{ 'dashboard.header.products' | translate }}</h3>
<app-product-result-card
    *ngFor="let product of products | slice: 0:resultsToDisplayPerCategory"
    [product]="product">
    <ng-container action *ngxPermissionsOnly="['ACCESS_SERVICE_BASKET']">
        <shared-status-label *ngIf="product.phase === Phase.PhasedOut" type="error">
            {{ 'product-list.expired' | translate }}
        </shared-status-label>
        <button
            appClickStopPropagation
            *ngIf="product.configurable"
            mat-flat-button
            color="primary"
            class="product-configure-button small-button"
            (click)="configureProduct(product)">
            {{ 'dashboard.configure' | translate }}
        </button>
        <div class="detail-button-wrapper">
            <button mat-icon-button (click)="visitProduct(product)">
                <shared-icon
                    icon="chevron-right"></shared-icon>
            </button>
        </div>
    </ng-container>
</app-product-result-card>

<app-show-more-button
    *ngIf="displayShowMoreButton"
    [ngPlural]="remainingItemCount"
    (click)="visitProductList()">
    <ng-template ngPluralCase="=1">{{
        'dashboard.showMoreProductsSingular' | translate
    }}</ng-template>
    <ng-template ngPluralCase="other">
        {{ 'dashboard.showMoreProductsPlural' | translate: { remaining: remainingItemCount } }}
    </ng-template>
</app-show-more-button>
