import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BasketDTO, GetBasketsResponse } from '@stobag/mystobag-basket-shared';
import { MyStobagRedirectService, serviceUrls } from '@stobag/mystobag-shared';
import { Subscription } from 'rxjs';
import { resultsToDisplayPerCategory } from 'src/app/constants';

@Component({
    selector: 'app-basket-results',
    templateUrl: './basket-results.component.html',
    styleUrls: ['./basket-results.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasketResultsComponent implements OnInit, OnDestroy {
    resultsToDisplayPerCategory = resultsToDisplayPerCategory;
    displayShowMoreButton = false;
    remainingItemCount: number;

    @Input() baskets: GetBasketsResponse;
    @Input() searchKeyword: string;

    private subscription = new Subscription();

    constructor(private redirectService: MyStobagRedirectService) {}

    ngOnInit() {
        this.remainingItemCount = this.baskets.totalElements - resultsToDisplayPerCategory;
        this.displayShowMoreButton = this.remainingItemCount > 0;
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    visitBasket(basket: BasketDTO) {
        this.redirectService.navigateToService(`${serviceUrls.basket}/${basket.id}`);
    }

    visitBasketList() {
        this.redirectService.navigateToService(
            `${serviceUrls.basket}?searchKey=${this.searchKeyword}&basketStatuses=OPEN,ORDERED,ARCHIVED`,
        );
    }
}
