import { ObserversModule } from '@angular/cdk/observers';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MyStobagBasketSharedModule } from '@stobag/mystobag-basket-shared';
import { MyStobagCatalogSharedModule } from '@stobag/mystobag-catalog-shared';
import { MyStobagDocumentSharedModule } from '@stobag/mystobag-document-shared';
import { MystobagHeaderModule } from '@stobag/mystobag-header';
import { MystobagNotificationSharedModule } from '@stobag/mystobag-notification-shared';
import { MyStobagOrderSharedModule } from '@stobag/mystobag-order-shared';
import {
    ConfigModule,
    HttpInterceptorService,
    MystobagSharedModule,
} from '@stobag/mystobag-shared';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxUiLoaderModule } from 'ngx-ui-loader';

import { SmoothHeightComponent } from './components/smooth-height/smooth-height.component';
import { MaterialModule } from './material.module';
import { UserAndAccountService } from './services/user-and-account.service';
import { WorkshopService } from './services/workshop.service';

@NgModule({
    imports: [
        TranslateModule.forChild({
            extend: true,
        }),
        NgxPermissionsModule.forChild(),
        CommonModule,
        ConfigModule,
        FlexModule,
        NgxUiLoaderModule,
        MaterialModule,
        MystobagSharedModule,
        MystobagHeaderModule,
        MyStobagOrderSharedModule,
        MyStobagBasketSharedModule,
        MyStobagDocumentSharedModule,
        MystobagNotificationSharedModule,
        MyStobagCatalogSharedModule,
        NgxMatSelectSearchModule,
        ObserversModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexModule,
        NgxUiLoaderModule,
        MystobagSharedModule,
        MystobagHeaderModule,
        MyStobagOrderSharedModule,
        MyStobagBasketSharedModule,
        MyStobagDocumentSharedModule,
        MystobagNotificationSharedModule,
        MyStobagCatalogSharedModule,
        NgxMatSelectSearchModule,
        MaterialModule,
        SmoothHeightComponent,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },

        WorkshopService,
        UserAndAccountService,
    ],
    declarations: [SmoothHeightComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
