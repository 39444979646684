<mat-card *ngxPermissionsOnly="['ACCESS_SERVICE_CATALOG']" (click)="navigateToCatalog()">
    <shared-icon class="page-icon scaled-up-icon" icon="catalog" [isSvgIcon]="true"></shared-icon>
    <span class="label">{{ 'dashboard.linkToCatalog' | translate }}</span>
    <shared-icon class="right-arrow" icon="chevron-right"></shared-icon>
</mat-card>

<mat-card *ngxPermissionsOnly="['ACCESS_SERVICE_SPAREPART']" (click)="navigateToSpareParts()">
    <shared-icon class="page-icon" icon="bolts" [isSvgIcon]="true"></shared-icon>
    <span class="label">{{ 'dashboard.linkToSpareParts' | translate }}</span>
    <shared-icon class="right-arrow" icon="chevron-right"></shared-icon>
</mat-card>

<mat-card
    *ngxPermissionsOnly="['ACCESS_SERVICE_SERVICEREPORT']"
    (click)="navigateToNewServiceReport()">
    <shared-icon class="page-icon" icon="technical-support" [isSvgIcon]="true"></shared-icon>
    <span class="label">{{ 'dashboard.linkToNewServiceReport' | translate }}</span>
    <shared-icon class="right-arrow" icon="chevron-right"></shared-icon>
</mat-card>
