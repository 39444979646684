<div class="position-relative" ngxUiLoaderBlurred [loaderId]="'news-list'">
    <div id="errorMessage" *ngIf="showError" class="error-element">
        {{ errorMessage | translate }}
    </div>
    <div
        *ngIf="newsByCategory$ | async as news"
        id="newsList"
        fxLayout="row"
        fxLayoutAlign="space-between center">
        <div *ngIf="!showError && (!news || news.length === 0)" class="empty-list">
            {{ 'news-list.emptyNewsList' | translate }}
        </div>
        <div class="news-cards">
            <app-news-card
                [news]="news[NewsCategory.News]"
                [category]="NewsCategory.News"
                (click)="navigateToNews(news[NewsCategory.News]?.id)"></app-news-card>
            <app-news-card
                [news]="news[NewsCategory.ReleaseNotes]"
                [category]="NewsCategory.ReleaseNotes"
                (click)="navigateToNews(news[NewsCategory.ReleaseNotes]?.id)"></app-news-card>
            <app-news-card
                class="delivery-category"
                [news]="news[NewsCategory.DeliveryUpdate]"
                [category]="NewsCategory.DeliveryUpdate"
                (click)="navigateToNews(news[NewsCategory.DeliveryUpdate]?.id)"></app-news-card>
        </div>
    </div>
    <ngx-ui-loader [loaderId]="'news-list'" [hasProgressBar]="false"></ngx-ui-loader>
</div>
