import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { TrainingCenterCategory } from '../models/training-center-category';
import { TrainingCenterContent } from '../models/training-center-content';
import { VideoTutorialDTO } from '../models/video-tutorial';
import { VideoTutorialFilterOptionsDto } from '../models/video-tutorial-filter-options';
import {
    VideoTutorialFilterBody,
    VideoTutorialListDto,
    VideoTutorialListQueryParams,
} from '../models/video-tutorial-list';

@Injectable({
    providedIn: 'root',
})
export class TrainingCenterApiService {
    backendUrl = `/training-center/api/training-center`;

    constructor(private httpClient: HttpClient) {}

    getCategories(): Observable<TrainingCenterCategory[]> {
        return this.httpClient.get<TrainingCenterCategory[]>(`${this.backendUrl}`);
    }

    getContentBySlug(slug: string): Observable<TrainingCenterContent> {
        return this.httpClient.get<TrainingCenterContent>(`${this.backendUrl}/${slug}`);
    }

    getDraftContentBySlug(slug: string): Observable<TrainingCenterContent> {
        const params = new HttpParams().append('stage', 'DRAFT');
        return this.httpClient.get<TrainingCenterContent>(`${this.backendUrl}/${slug}`, {
            params,
        });
    }

    listVideoTutorials(
        body: VideoTutorialFilterBody = {},
        queryParams: VideoTutorialListQueryParams = {},
    ) {
        const params = {
            page: queryParams.page ?? 0,
            size: 20,
        };
        return this.httpClient.post<VideoTutorialListDto>(
            `${this.backendUrl}/video-tutorial`,
            body,
            { params },
        );
    }

    getVideoTutorialFilterOptions() {
        return this.httpClient.get<VideoTutorialFilterOptionsDto>(
            `${this.backendUrl}/video-tutorial/filter-options`,
        );
    }

    getVideoTutorialById(id: string) {
        return this.httpClient.get<VideoTutorialDTO>(`${this.backendUrl}/video-tutorial/${id}`);
    }
}
