import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class HealthCheckService {
    constructor(private httpClient: HttpClient) {}

    checkServices(): Observable<Map<string, boolean>> {
        return this.httpClient.get<Map<string, boolean>>(`/dashboard/api/health`);
    }
}
