<h3 class="white">{{ 'dashboard.header.spareParts' | translate }}</h3>
<div
    *ngFor="let sparePartGroup of _sparePartGroups | slice : 0 : resultsToDisplayPerCategory"
    class="spare-part-container">
    <shared-spare-part-card
        class="spare-part-card"
        [name]="sparePartGroup.name"
        [catalogNr]="sparePartGroup.catalogNr"
        [productImages]="sparePartGroup.productImages">
        <ng-container actions>
            <ng-container *ngIf="sparePartGroup.configurationType === 'WIZARD'">
                <button
                    id="configure{{ sparePartGroup.catalogNr }}"
                    mat-flat-button
                    class="small-button"
                    color="primary"
                    matTooltipPosition="above"
                    (click)="configureWithWizard(sparePartGroup)">
                    {{ 'spare-part-list.configure' | translate }}
                </button>
            </ng-container>
            <ng-container *ngIf="sparePartGroup.configurationType === 'SELECTOR'">
                <button
                    id="configure{{ sparePartGroup.catalogNr }}"
                    mat-flat-button
                    class="small-button"
                    color="primary"
                    matTooltipPosition="above"
                    (click)="useSelector(sparePartGroup)">
                    {{ 'spare-part-list.configure' | translate }}
                </button>
            </ng-container>
            <ng-container *ngIf="sparePartGroup.configurationType === 'SIMPLE'">
                <div class="reverse-flex-order">

                    <button
                        id="addToBasket{{ sparePartGroup.catalogNr }}"
                        class="spare-part-add-to-cart-button"
                        mat-flat-button
                        color="primary"
                        matTooltipPosition="above"
                        (click)="addToBasket(sparePartGroup)">
                        <shared-icon
                            color="primary"
                            class="spare-part-addIcon"
                            icon="shopping-cart-plus"></shared-icon>
                    </button>
                    <div
                        *ngIf="amounts[sparePartGroup.catalogNr]"
                        fxLayout="row"
                        fxLayoutAlign="flex-start center">
                        <shared-number-input
                            [formControl]="amounts[sparePartGroup.catalogNr]"
                            [minValue]="1"
                            [maxValue]="100"></shared-number-input>
                        <span class="spare-part-unit">{{
                            sparePartGroup.unit | formatSparePartUnit | async
                        }}</span>
                    </div>

                </div>
            </ng-container>
        </ng-container>
    </shared-spare-part-card>
</div>

<app-show-more-button
    *ngIf="displayShowMoreButton"
    [ngPlural]="remainingItemCount"
    (click)="visitSparePartList()">
    <ng-template ngPluralCase="=1">{{
        'dashboard.showMoreProductsSingular' | translate
    }}</ng-template>
    <ng-template ngPluralCase="other">
        {{ 'dashboard.showMoreProductsPlural' | translate : { remaining: remainingItemCount } }}
    </ng-template>
</app-show-more-button>
