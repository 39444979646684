import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    BasketFilterParams,
    BasketListService,
    GetBasketsResponse,
} from '@stobag/mystobag-basket-shared';
import { Phase, ProductDTO, SegmentDTO, SparePartGroupDTO } from '@stobag/mystobag-catalog-shared';
import {
    DocumentDTO,
    DocumentFilterRequest,
    DocumentReadService,
} from '@stobag/mystobag-document-shared';
import { OfferDTO, OrderDTO } from '@stobag/mystobag-order-shared';
import { EquipmentDTO, ServiceReportDTO } from '@stobag/mystobag-shared';
import { NgxPermissionsService } from 'ngx-permissions';
import { BehaviorSubject, combineLatest, from, Observable, of } from 'rxjs';
import { catchError, debounceTime, filter, map, startWith, switchMap } from 'rxjs/operators';

import { resultsToDisplayPerCategory } from '../../../constants';
import { Page, SearchResult } from '../models/search-result';

interface SearchParams {
    keyword: string;
    searchFilter: SearchFilter;
}

export enum SearchFilter {
    Product = 'PRODUCT',
    SparePart = 'SPARE_PART',
    Basket = 'BASKET',
    Order = 'ORDER',
    Offer = 'OFFER',
    ServiceReport = 'SERVICE_REPORT',
    Document = 'DOCUMENT',
    Equipment = 'EQUIPMENT',
}

@Injectable()
export class SearchService {
    readonly MIN_SEARCH_KEY_LENGTH = 3;
    private searchParams = new BehaviorSubject<SearchParams>({ keyword: '', searchFilter: null });

    constructor(
        private httpClient: HttpClient,
        private permissionsService: NgxPermissionsService,
        private basketListService: BasketListService,
        private documentReadService: DocumentReadService,
    ) {}

    getSearchResult$(): Observable<SearchResult> {
        return this.searchParams.asObservable().pipe(
            debounceTime(1000),
            switchMap(searchParams => {
                if (searchParams.keyword?.length < this.MIN_SEARCH_KEY_LENGTH) {
                    return of(null);
                }
                const observables = [
                    this.searchProducts$(searchParams).pipe(startWith(null)),
                    this.searchSparePartGroups(searchParams).pipe(startWith(null)),
                    this.searchBaskets$(searchParams).pipe(startWith(null)),
                    this.searchOrders$(searchParams).pipe(startWith(null)),
                    this.searchOffers$(searchParams).pipe(startWith(null)),
                    this.searchServiceReports$(searchParams).pipe(startWith(null)),
                    this.searchDocuments$(searchParams).pipe(startWith(null)),
                    this.searchEquipments$(searchParams).pipe(startWith(null)),
                ];
                return combineLatest(observables).pipe(
                    map(response => {
                        const [
                            products,
                            sparePartGroups,
                            baskets,
                            orders,
                            offers,
                            serviceReports,
                            documents,
                            equipments,
                        ] = response;
                        return {
                            keyword: searchParams.keyword,
                            products: (products ?? []) as ProductDTO[],
                            sparePartGroups: (sparePartGroups ?? []) as SparePartGroupDTO[],
                            baskets: (baskets ?? {
                                content: [],
                                totalElements: 0,
                            }) as GetBasketsResponse,
                            orders: (orders ?? []) as OrderDTO[],
                            offers: (offers ?? []) as OfferDTO[],
                            serviceReports: (serviceReports ?? []) as ServiceReportDTO[],
                            documents: (documents ?? {
                                content: [],
                                totalElements: 0,
                            }) as Page<DocumentDTO>,
                            equipments: (equipments ?? []) as EquipmentDTO[],
                            searchInProgress:
                                (!searchParams.searchFilter &&
                                    response.some(item => item === null)) ||
                                (searchParams.searchFilter &&
                                    response.every(item => item === null)),
                        };
                    }),
                );
            }),
        );
    }

    search(keyword: string, searchFilter: SearchFilter) {
        this.searchParams.next({
            keyword,
            searchFilter,
        });
    }

    private searchProducts$({ keyword, searchFilter }): Observable<ProductDTO[]> {
        return from(this.permissionsService.hasPermission('ACCESS_SERVICE_CATALOG')).pipe(
            filter(hasPermission => hasPermission),
            switchMap(() => {
                return !searchFilter || SearchFilter.Product === searchFilter
                    ? this.httpClient.get<SegmentDTO[]>(`/catalog/api/catalog/segments`).pipe(
                          map(segments => {
                              const products: ProductDTO[] = [];
                              segments.forEach(segment =>
                                  segment.subsegments.forEach(subsegment =>
                                      subsegment.products.forEach(product => {
                                          product.url = `product/${product.id}/overview`;
                                          products.push(product);
                                      }),
                                  ),
                              );
                              return products;
                          }),
                          map(products =>
                              products.filter(
                                  product =>
                                      product.phase !== Phase.PhasedOut &&
                                      product.phase !== Phase.PhasingIn &&
                                      (product.name?.toLowerCase().includes(keyword) ||
                                          product.id?.toLowerCase().includes(keyword)),
                              ),
                          ),
                          catchError(() => {
                              return [];
                          }),
                      )
                    : of(null);
            }),
        );
    }

    private searchBaskets$({ keyword, searchFilter }): Observable<GetBasketsResponse> {
        return from(this.permissionsService.hasPermission('ACCESS_SERVICE_BASKET')).pipe(
            filter(hasPermission => hasPermission),
            switchMap(() => {
                if (!searchFilter || SearchFilter.Basket === searchFilter) {
                    const filterParams: BasketFilterParams = {
                        page: 0,
                        size: resultsToDisplayPerCategory,
                        searchKey: keyword,
                    };
                    return this.basketListService.getBaskets(filterParams).pipe(
                        catchError(() => {
                            return [];
                        }),
                    );
                } else {
                    return of(null);
                }
            }),
        );
    }

    private searchOrders$({ keyword, searchFilter }) {
        return from(this.permissionsService.hasPermission('ACCESS_SERVICE_ORDER')).pipe(
            filter(hasPermission => hasPermission),
            switchMap(() => {
                return !searchFilter || SearchFilter.Order === searchFilter
                    ? this.httpClient
                          .get<Page<OrderDTO>>(`/order/api/order?searchKey=${keyword}`)
                          .pipe(
                              map(orders => this.removeDuplicates<OrderDTO>(orders.content)),
                              catchError(() => {
                                  return [];
                              }),
                          )
                    : of(null);
            }),
        );
    }

    private searchOffers$({ keyword, searchFilter }) {
        return from(this.permissionsService.hasPermission('ACCESS_SERVICE_OFFER')).pipe(
            switchMap(hasPermission => {
                if (hasPermission) {
                    return !searchFilter || SearchFilter.Offer === searchFilter
                        ? this.httpClient
                              .get<Page<OfferDTO>>(`/order/api/offer?searchKey=${keyword}`)
                              .pipe(
                                  map(offers => this.removeDuplicates<OfferDTO>(offers.content)),
                                  catchError(() => {
                                      return [];
                                  }),
                              )
                        : of(null);
                } else {
                    return of([]);
                }
            }),
        );
    }

    private removeDuplicates<T>(dtos: T[]): T[] {
        return Array.from(new Set(dtos));
    }

    private searchSparePartGroups({ keyword, searchFilter }): Observable<SparePartGroupDTO[]> {
        return from(this.permissionsService.hasPermission('ACCESS_SERVICE_SPAREPART')).pipe(
            filter(hasPermission => hasPermission),
            switchMap(() => {
                return !searchFilter || SearchFilter.SparePart === searchFilter
                    ? this.httpClient
                          .get<{ content: SparePartGroupDTO[] }>(
                              `/catalog/api/catalog/spare-part-groups`,
                              {
                                  params: this.generateSparePartGroupFilterParam(keyword),
                              },
                          )
                          .pipe(map(content => content.content))
                    : of(null);
            }),
        );
    }

    private generateSparePartGroupFilterParam(keyword: string): HttpParams {
        let paramValue = new HttpParams();
        paramValue = paramValue.set('search-term', keyword);
        return paramValue;
    }

    private searchServiceReports$({ keyword, searchFilter }): Observable<ServiceReportDTO[]> {
        return from(this.permissionsService.hasPermission('ACCESS_SERVICE_SERVICEREPORT')).pipe(
            filter(hasPermission => hasPermission),
            switchMap(() => {
                return !searchFilter || SearchFilter.ServiceReport === searchFilter
                    ? this.httpClient.get<any[]>(`/service-report/api/service-report/all`).pipe(
                          map(serviceReports =>
                              serviceReports.filter(
                                  serviceReport =>
                                      `${serviceReport.id}`.toLowerCase().includes(keyword) ||
                                      serviceReport.articleNumber
                                          ?.toLowerCase()
                                          .includes(keyword) ||
                                      serviceReport.commission?.toLowerCase().includes(keyword) ||
                                      serviceReport.productName?.toLowerCase().includes(keyword) ||
                                      serviceReport.orderNumber?.toLowerCase().includes(keyword) ||
                                      serviceReport.serialNumber?.toLowerCase().includes(keyword),
                              ),
                          ),
                          catchError(() => {
                              return [];
                          }),
                      )
                    : of(null);
            }),
        );
    }

    private searchDocuments$({ keyword, searchFilter }) {
        return from(this.permissionsService.hasPermission('ACCESS_SERVICE_DOCUMENT')).pipe(
            filter(hasPermission => hasPermission),
            switchMap(() => {
                if (!searchFilter || SearchFilter.Document === searchFilter) {
                    const filters: DocumentFilterRequest = {
                        searchKey: keyword,
                        documentType: [],
                        documentCategories: null,
                        domain: [],
                        productSegmentId: [],
                        salesContexts: [],
                        availableFor: ['PARTNERNET'],
                        page: {
                            page: 0,
                            size: 20,
                            sort: { direction: 'asc', order: 'updated' },
                        },
                    };
                    return this.documentReadService.findAllFiltered(filters);
                } else {
                    return of(null);
                }
            }),
            catchError(() => {
                return [];
            }),
        );
    }

    private searchEquipments$({ keyword, searchFilter }) {
        return from(this.permissionsService.hasPermission('ACCESS_SERVICE_ORDER')).pipe(
            filter(hasPermission => hasPermission),
            switchMap(() => {
                return !searchFilter || SearchFilter.Equipment === searchFilter
                    ? this.httpClient.get<EquipmentDTO[]>(`/order/api/order/equipment/${keyword}`)
                    : of(null);
            }),
            catchError(() => {
                return [];
            }),
        );
    }
}
