import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { NewsCategory, NewsCategoryIconPipe, NewsDTO } from '@stobag/mystobag-notification-shared';

@Component({
    selector: 'app-news-card',
    templateUrl: './news-card.component.html',
    styleUrls: ['./news-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsCardComponent implements OnInit {
    NewsCategory = NewsCategory;
    emptyIcon: string;
    isEmptyIconSvg: boolean;
    private _category: NewsCategory;

    @Input() news: NewsDTO;
    @Input() category: NewsCategory;

    @HostBinding('class') get categoryClass() {
        switch (this.category) {
            case NewsCategory.ReleaseNotes:
                return 'release-notes-category';
            case NewsCategory.DeliveryUpdate:
                return 'delivery-update-category';
            case NewsCategory.News:
            default:
                return '';
        }
    }

    constructor(private newsCategoryIconPipe: NewsCategoryIconPipe) {}

    ngOnInit() {
        const { icon, isSvgIcon } = this.newsCategoryIconPipe.transform(this._category);
        this.emptyIcon = icon;
        this.isEmptyIconSvg = isSvgIcon;
    }

    getContent(item: NewsDTO): string {
        const length = item.content.text?.length;
        const suffix = length > 200 ? ' ...' : '';
        const replaced = item.content.text?.replace(/\\n\\n/g, '\\n').replace(/\\n/g, '\n');
        return (replaced?.length > 200 ? replaced.substring(0, 200) : replaced) + suffix;
    }
}
