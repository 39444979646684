import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BrowserCheckService } from '@stobag/mystobag-shared';

@Component({
    selector: 'app-browser-check-popup',
    templateUrl: './browser-check-popup.component.html',
})
export class BrowserCheckPopupComponent {
    readonly supportedBrowsers;

    constructor(
        public dialogRef: MatDialogRef<BrowserCheckPopupComponent>,
        private browserCheckService: BrowserCheckService,
    ) {
        this.supportedBrowsers = browserCheckService.supportedBrowsers;
    }

    close() {
        this.dialogRef.close();
    }
}
