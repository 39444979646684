import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
    BasketSelectDialogComponent,
    BasketSelectDialogData,
} from '@stobag/mystobag-basket-shared';
import { CatalogItemDTO, CatalogSharedService } from '@stobag/mystobag-catalog-shared';
import {
    AuthenticationService,
    EquipmentDTO,
    MyStobagRedirectService,
    serviceUrls,
    STOBAG_PERMISSIONS,
} from '@stobag/mystobag-shared';
import { NgxPermissionsService } from 'ngx-permissions';
import { from, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { OrderItemWithAccessInfo } from '../../search-results.component';

@Component({
    selector: 'app-equipment-card',
    templateUrl: './equipment-card.component.html',
    styleUrls: ['./equipment-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentCardComponent implements OnInit, OnDestroy {
    orderItem$: Observable<OrderItemWithAccessInfo>;

    @Input() equipment: EquipmentDTO;

    private subscription = new Subscription();

    constructor(
        private authenticationService: AuthenticationService,
        private ngxPermissionsService: NgxPermissionsService,
        private matDialog: MatDialog,
        private redirectService: MyStobagRedirectService,
        private catalogService: CatalogSharedService,
        private router: Router,
    ) {}

    ngOnInit() {
        this.orderItem$ = this.getOrderItemForEquipment$(this.equipment);
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    navigateToSparePartReorder(
        orderId: string,
        item: OrderItemWithAccessInfo,
        serialNumber: string,
    ) {
        const pagePath = `/spare-part`;
        this.getProductName$(item).subscribe(productName => {
            const qParams = {
                queryParams: {
                    productName,
                    orderId,
                    position: item.position,
                    serialNumber,
                },
            };
            const urlPath = this.router.createUrlTree([pagePath], qParams).toString();
            this.redirectService.navigateToService(`${serviceUrls.order}${urlPath}`);
        });
    }

    openBasketSelect(configuredItemId: string) {
        const data: BasketSelectDialogData = {
            configuredItemId,
            amount: null,
            unit: null,
        };
        this.matDialog.open(BasketSelectDialogComponent, {
            disableClose: true,
            data,
        });
    }

    shouldDisplaySparePartList(
        item: OrderItemWithAccessInfo,
        deliveryDate: string | Date,
    ): boolean {
        return (
            !item.cancelled &&
            this.isAlreadyDelivered(new Date(deliveryDate)) &&
            this.isFertigProduct(item)
        );
    }

    shouldDisplayFabricReorderButton(item: OrderItemWithAccessInfo): boolean {
        return (
            item?.configurationMetadata && this.hasSupportedFabricKmat(item.configurationMetadata)
        );
    }

    getProductImage$(orderItem: OrderItemWithAccessInfo): Observable<string> {
        return this.getProduct$(orderItem).pipe(
            map(item => {
                return item?.productImages?.[0];
            }),
        );
    }

    createServiceReport(serialNumber: string) {
        this.redirectService.navigateToService(
            `${serviceUrls.serviceReport}/submit?serialNumber=${serialNumber}`,
        );
    }

    visitOrder(orderId: string) {
        this.redirectService.navigateToService(`${serviceUrls.order}/${orderId}`);
    }

    private getProductName$(orderItem: OrderItemWithAccessInfo): Observable<string> {
        return this.getProduct$(orderItem).pipe(
            map(product => (product ? product.name : orderItem.name)),
        );
    }

    private hasSupportedFabricKmat(configurationMetadata) {
        const tuchKmatParamId = 'ZVC_KMAT_TUCH';
        const supportedFabricKmats = ['5000'];
        const kmat = configurationMetadata[tuchKmatParamId]?.valueId;
        return supportedFabricKmats.includes(kmat);
    }

    private isAlreadyDelivered(date: Date): boolean {
        const now = new Date();
        now.setHours(0, 0, 0, 0);
        date.setHours(0, 0, 0, 0);
        return date < now;
    }

    private isFertigProduct(item: OrderItemWithAccessInfo): boolean {
        return item.productId?.charAt(0).match(/[a-z]/i) !== null;
    }

    private getOrderItemForEquipment$(
        equipment: EquipmentDTO,
    ): Observable<OrderItemWithAccessInfo> {
        const order = equipment.relatedOrder.items.find(
            item => item.position === equipment.deliveryItem,
        );
        const ownsOrder =
            equipment.relatedOrder.accountId === this.authenticationService.getAccountNumber();

        return this.isStobagUser$().pipe(
            map(hasPermission => {
                const orderItemWithAccessInfo: OrderItemWithAccessInfo = {
                    ...order,
                    hasDetails: hasPermission || ownsOrder,
                };
                return orderItemWithAccessInfo;
            }),
        );
    }

    private isStobagUser$(): Observable<boolean> {
        return from(this.ngxPermissionsService.hasPermission(STOBAG_PERMISSIONS));
    }

    private getProduct$(orderItem: OrderItemWithAccessInfo): Observable<CatalogItemDTO> {
        return this.catalogService.getCatalogItems().pipe(
            map(catalogItems => {
                return catalogItems.find(item => item.id === orderItem.productId);
            }),
        );
    }
}
