import { Component } from '@angular/core';
import { AnalyticsService, MyStobagRedirectService } from '@stobag/mystobag-shared';
import { serviceUrls } from '@stobag/mystobag-shared';

@Component({
    selector: 'app-link-section',
    templateUrl: './link-section.component.html',
    styleUrls: ['./link-section.component.scss'],
})
export class LinkSectionComponent {
    constructor(
        private redirectService: MyStobagRedirectService,
        private analyticsService: AnalyticsService,
    ) {}

    navigateToCatalog() {
        this.analyticsService.sendEvent('dashboard_catalog_button_clicked');
        this.redirectService.navigateToService(serviceUrls.catalog);
    }

    navigateToSpareParts() {
        this.analyticsService.sendEvent('dashboard_spare_part_button_clicked');
        this.redirectService.navigateToService(`${serviceUrls.catalog}/spare-parts`);
    }

    navigateToNewServiceReport() {
        this.analyticsService.sendEvent('dashboard_service_report_button_clicked');
        this.redirectService.navigateToService(`${serviceUrls.serviceReport}/submit`);
    }
}
