import { HttpBackend } from '@angular/common/http';
import {
    i18nMyStobagBasketSharedDE,
    i18nMyStobagBasketSharedEN,
    i18nMyStobagBasketSharedFR,
    i18nMyStobagBasketSharedIT,
    i18nMyStobagBasketSharedNL,
} from '@stobag/mystobag-basket-shared';
import {
    i18nMyStobagCatalogSharedDE,
    i18nMyStobagCatalogSharedEN,
    i18nMyStobagCatalogSharedFR,
    i18nMyStobagCatalogSharedIT,
    i18nMyStobagCatalogSharedNL,
} from '@stobag/mystobag-catalog-shared';
import {
    i18nMyStobagDocumentSharedDE,
    i18nMyStobagDocumentSharedEN,
    i18nMyStobagDocumentSharedFR,
    i18nMyStobagDocumentSharedIT,
    i18nMyStobagDocumentSharedNL,
} from '@stobag/mystobag-document-shared';
import {
    i18nMyStobagHeaderDE,
    i18nMyStobagHeaderEN,
    i18nMyStobagHeaderFR,
    i18nMyStobagHeaderIT,
    i18nMyStobagHeaderNL,
} from '@stobag/mystobag-header';
import {
    i18nMyStobagOrderSharedDE,
    i18nMyStobagOrderSharedEN,
    i18nMyStobagOrderSharedFR,
    i18nMyStobagOrderSharedIT,
    i18nMyStobagOrderSharedNL,
} from '@stobag/mystobag-order-shared';
import {
    ConfigService,
    CustomTranslateLoader,
    i18nMyStobagSharedDE,
    i18nMyStobagSharedEN,
    i18nMyStobagSharedFR,
    i18nMyStobagSharedIT,
    i18nMyStobagSharedNL,
    LanguageObjects,
} from '@stobag/mystobag-shared';
import {
    i18nPartnernetLeadSharedDE,
    i18nPartnernetLeadSharedEN,
    i18nPartnernetLeadSharedFR,
    i18nPartnernetLeadSharedIT,
    i18nPartnernetLeadSharedNL,
} from '@stobag/partnernet-lead-shared';

const languageObjects: LanguageObjects = {
    en: [
        i18nMyStobagHeaderEN,
        i18nMyStobagSharedEN,
        i18nMyStobagBasketSharedEN,
        i18nMyStobagOrderSharedEN,
        i18nMyStobagDocumentSharedEN,
        i18nMyStobagCatalogSharedEN,
        i18nPartnernetLeadSharedEN,
    ],
    de: [
        i18nMyStobagHeaderDE,
        i18nMyStobagSharedDE,
        i18nMyStobagBasketSharedDE,
        i18nMyStobagOrderSharedDE,
        i18nMyStobagDocumentSharedDE,
        i18nMyStobagCatalogSharedDE,
        i18nPartnernetLeadSharedDE,
    ],
    it: [
        i18nMyStobagHeaderIT,
        i18nMyStobagSharedIT,
        i18nMyStobagBasketSharedIT,
        i18nMyStobagOrderSharedIT,
        i18nMyStobagDocumentSharedIT,
        i18nMyStobagCatalogSharedIT,
        i18nPartnernetLeadSharedIT,
    ],
    fr: [
        i18nMyStobagHeaderFR,
        i18nMyStobagSharedFR,
        i18nMyStobagBasketSharedFR,
        i18nMyStobagOrderSharedFR,
        i18nMyStobagDocumentSharedFR,
        i18nMyStobagCatalogSharedFR,
        i18nPartnernetLeadSharedFR,
    ],
    nl: [
        i18nMyStobagHeaderNL,
        i18nMyStobagSharedNL,
        i18nMyStobagBasketSharedNL,
        i18nMyStobagOrderSharedNL,
        i18nMyStobagDocumentSharedNL,
        i18nMyStobagCatalogSharedNL,
        i18nPartnernetLeadSharedNL,
    ],
};

export function translateLoaderFactory(http: HttpBackend, config: ConfigService) {
    return new CustomTranslateLoader(http, config, languageObjects);
}
