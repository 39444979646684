import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EquipmentDTO } from '@stobag/mystobag-shared';
import { resultsToDisplayPerCategory } from 'src/app/constants';

@Component({
    selector: 'app-equipment-results',
    templateUrl: './equipment-results.component.html',
    styleUrls: ['./equipment-results.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentResultsComponent {
    resultsToDisplayPerCategory = resultsToDisplayPerCategory;

    @Input() equipments: EquipmentDTO[];
}
