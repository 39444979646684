import {
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    SearchBarComponent,
    SearchFieldDefBase,
    SearchSelectField,
    SearchTextField,
} from '@stobag/mystobag-header';
import { AnalyticsService } from '@stobag/mystobag-shared';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { deepCopy } from '../core/util/util';
import { SearchResult } from './models/search-result';
import { SearchFilter, SearchService } from './services/search.service';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
    @ViewChild('backdrop') backdropRef: ElementRef;
    @ViewChild('searchBar', { read: ElementRef }) searchBarRef: ElementRef;
    @ViewChild('searchBar') searchBarReComponent: SearchBarComponent;

    @Input() dashboardContentRef: ElementRef;

    searchType: SearchFilter;
    isSearchActive = false;
    searchResult$: Observable<SearchResult>;
    searchResult: SearchResult;
    searchDebounce = new Subject<KeyboardEvent>();
    searchFieldDefs: Array<SearchFieldDefBase<unknown>>;

    @HostListener('document:keydown.escape', ['$event'])
    onKeydownEscape() {
        this.onSearchClose();
    }

    @HostBinding('class.active') get isActive() {
        return this.isSearchActive;
    }

    constructor(
        private searchService: SearchService,
        private analyticsService: AnalyticsService,
        private elementRef: ElementRef,
        private ngxPermissionsService: NgxPermissionsService,
    ) {}

    ngOnInit(): void {
        this.searchFieldDefs = this.getSearchFieldDefs();
    }

    search(keyword, searchFilter): void {
        const lowerCaseKeyword = keyword?.toLowerCase() || '';
        this.searchService.search(lowerCaseKeyword, searchFilter);
        this.searchResult$ = this.searchService
            .getSearchResult$()
            .pipe(map(resp => (resp ? deepCopy(resp) : null)));
    }

    activateSearch() {
        if (!this.isSearchActive) {
            this.analyticsService.sendEvent('dashboard_search_activated');
        }

        const { top } = this.searchBarRef.nativeElement.getBoundingClientRect();
        const actionsHeight = 64;
        const actionsPadding = Math.round(top) - actionsHeight;
        this.elementRef.nativeElement.style.setProperty('--actions-padding', `${actionsPadding}px`);
        this.isSearchActive = true;
    }

    onSearchClose() {
        this.isSearchActive = false;
        this.searchBarReComponent.clear();
        this.elementRef.nativeElement.scrollTop = 0;
    }

    setBackdropStyle(containerClientRect: DOMRect) {
        // We cannot handle backdrop's height with CSS since the overflowing component is the MatDrawerContent
        // structural component located outer than this component. Height: 100% will only give
        // backdrop the height of the container without overflowing.

        // The search results need to be rendered in the DOM in order to calculate the container's true height. To avoid
        // flashing opacity is set to 0 until the content is rendered and the height is read and set on the backdrop.
        const newBackdropHeight = this.getBackdropHeight(containerClientRect);
        (this.backdropRef.nativeElement as HTMLElement).style.height = `${newBackdropHeight}px`;
    }

    private getBackdropHeight(containerClientRect: DOMRect): number {
        const scrollTop = this.elementRef.nativeElement.scrollTop;
        const { height: searchResultContainerHeight, top: searchResultContainerTop } =
            containerClientRect;
        return searchResultContainerHeight + searchResultContainerTop + scrollTop;
    }

    private getSearchFieldDefs(): Array<SearchFieldDefBase<unknown>> {
        const permissions = this.ngxPermissionsService.getPermissions();
        return [
            new SearchTextField({
                key: 'keyword',
                label: 'dashboard.search',
                placeholder: 'dashboard.searchPlaceholder',
            }),
            new SearchSelectField({
                key: 'type',
                label: 'dashboard.category',
                options: this.getSearchTypeOptions(Object.keys(permissions)),
                multiple: false,
            }),
        ];
    }

    onSearchFilterChange({ keyword, type }: Record<string, unknown>) {
        this.searchType = type as SearchFilter;
        this.search(keyword, type);
    }

    private getSearchTypeOptions(permissions: string[]) {
        const options = [];

        if (permissions.includes('ACCESS_SERVICE_CATALOG')) {
            options.push({
                key: SearchFilter.Product,
                label: 'dashboard.filter.product',
            });
        }

        if (permissions.includes('ACCESS_SERVICE_SPAREPART')) {
            options.push({
                key: SearchFilter.SparePart,
                label: 'dashboard.filter.sparePart',
            });
        }

        if (permissions.includes('ACCESS_SERVICE_BASKET')) {
            options.push({
                key: SearchFilter.Basket,
                label: 'dashboard.filter.basket',
            });
        }

        if (permissions.includes('ACCESS_SERVICE_ORDER')) {
            options.push({
                key: SearchFilter.Order,
                label: 'dashboard.filter.order',
            });
        }

        if (permissions.includes('ACCESS_SERVICE_OFFER')) {
            options.push({
                key: SearchFilter.Offer,
                label: 'dashboard.filter.offer',
            });
        }

        if (permissions.includes('ACCESS_SERVICE_SERVICEREPORT')) {
            options.push({
                key: SearchFilter.ServiceReport,
                label: 'dashboard.filter.serviceReport',
            });
        }

        if (permissions.includes('ACCESS_SERVICE_DOCUMENT')) {
            options.push({
                key: SearchFilter.Document,
                label: 'dashboard.filter.document',
            });
        }

        if (permissions.includes('ACCESS_SERVICE_ORDER')) {
            options.push({
                key: SearchFilter.Equipment,
                label: 'dashboard.filter.equipment',
            });
        }

        return options;
    }
}
