import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Phase, ProductDTO, SparePartDTO } from '@stobag/mystobag-catalog-shared';
import {
    AuthenticationService,
    MyStobagRedirectService,
    serviceUrls,
} from '@stobag/mystobag-shared';
import { Subscription } from 'rxjs';

import { resultsToDisplayPerCategory } from '../../../../../constants';

@Component({
    selector: 'app-product-results',
    templateUrl: './product-results.component.html',
    styleUrls: ['./product-results.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductResultsComponent implements OnInit, OnDestroy {
    Phase = Phase;
    resultsToDisplayPerCategory = resultsToDisplayPerCategory;
    displayShowMoreButton = false;
    remainingItemCount: number;

    @Input() products: ProductDTO[];
    @Input() searchKeyword: string;

    private subscription = new Subscription();

    constructor(
        private authenticationService: AuthenticationService,
        private redirectService: MyStobagRedirectService,
        private translateService: TranslateService,
        private router: Router,
    ) {}

    ngOnInit() {
        this.remainingItemCount = this.products.length - resultsToDisplayPerCategory;
        this.displayShowMoreButton = this.remainingItemCount > 0;
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    configureProduct(product: ProductDTO | SparePartDTO) {
        const language = this.translateService.currentLang;
        const hostUrl = this.router.url;
        const cpqUrl = `/cpq/#/newConfiguration/${language}/productId/${product.id}?&cancelUrl=${hostUrl}`;
        this.subscription.add(
            this.authenticationService.refreshTokenV2().subscribe({
                next: () => this.redirectService.navigateToExternalUrl(cpqUrl),
                error: () => this.authenticationService.logout(),
            }),
        );
    }

    visitProduct(product: ProductDTO) {
        this.redirectService.navigateToService(`${serviceUrls.catalog}/${product.url}`);
    }

    visitProductList() {
        this.redirectService.navigateToService(
            `${serviceUrls.catalog}?search=${this.searchKeyword}`,
        );
    }
}
