import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AnalyticsService, ConfigService } from '@stobag/mystobag-shared';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    constructor(
        analytics: AnalyticsService,
        private sanitizer: DomSanitizer,
        private matIconRegistry: MatIconRegistry,
        private dateAdapter: DateAdapter<never>, // Must inject dateAdapter here in order to create the service once
        @Inject(DOCUMENT) private document: Document,
        private configService: ConfigService,
    ) {
        this.configureCustomMatIcons();
        analytics.init();
        if (this.configService.getConfig().headerV2) {
            this.document.body.classList.add('v2');
        }
    }

    private configureCustomMatIcons() {
        this.addCustomIconToRegistry('catalog', true);
        this.addCustomIconToRegistry('bolts');
        this.addCustomIconToRegistry('technical-support');
        this.addCustomIconToRegistry('graduation-cap', true);
    }

    private addCustomIconToRegistry(iconName: string, localAsset = false) {
        const baseIconUrl = localAsset ? `/dashboard/assets/img/links/` : '/static/icons/';
        const safeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            `${baseIconUrl}${iconName}.svg`,
        );
        this.matIconRegistry.addSvgIcon(iconName, safeResourceUrl);
    }
}
