import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { OrderItemDTO } from '@stobag/mystobag-shared';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';

import { SearchResult } from '../../models/search-result';

export interface OrderItemWithAccessInfo extends OrderItemDTO {
    hasDetails: boolean;
}

@Component({
    selector: 'app-search-results',
    templateUrl: './search-results.component.html',
    styleUrls: ['./search-results.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchResultsComponent implements OnChanges, AfterViewInit, OnDestroy {
    private componentRendered = false;
    private subscription: Subscription = new Subscription();

    @Input() searchResponse: SearchResult;
    @Input() searchFilter: string;

    @Output() containerClientRect: EventEmitter<DOMRect> = new EventEmitter<DOMRect>();

    @ViewChild('searchResultContainer') searchResultContainerRef: ElementRef;

    constructor(private nxUiLoaderService: NgxUiLoaderService) {}

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.searchResponse?.currentValue) {
            if (this.searchResponse.searchInProgress) {
                this.nxUiLoaderService.startLoader('search-in-progress');
            } else {
                this.nxUiLoaderService.stopLoader('search-in-progress');
            }
        }

        if (changes?.searchResponse?.currentValue?.keyword && this.componentRendered) {
            this.emitContainerClientRect();
        }
    }

    ngAfterViewInit() {
        this.componentRendered = true;
        this.emitContainerClientRect();
    }

    hasNoResult(searchResponse: SearchResult) {
        if (searchResponse.searchInProgress) {
            return false;
        }

        return (
            searchResponse.equipments.length === 0 &&
            searchResponse.serviceReports.length === 0 &&
            searchResponse.orders.length === 0 &&
            searchResponse.offers.length === 0 &&
            searchResponse.documents.content.length === 0 &&
            searchResponse.baskets.content.length === 0 &&
            searchResponse.sparePartGroups.length === 0 &&
            searchResponse.products.length === 0
        );
    }

    private emitContainerClientRect() {
        setTimeout(() => {
            // wait until response is rendered
            const clientRect = this.searchResultContainerRef.nativeElement.getBoundingClientRect();
            this.containerClientRect.emit(clientRect);
        });
    }
}
