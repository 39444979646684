<div fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign="flex-start center" fxLayoutAlign.xs="flex-start" fxLayoutAlign.sm="flex-start center">
    <div class="image" sharedTooltipTrigger>
        <ng-container *ngIf="product.productImages?.length > 0; else placeholder">
            <img class="result-image" [src]="product.productImages[0]" />

            <shared-tooltip direction="right">
                <img class="tooltip-image" [src]="product.productImages[0]" />
            </shared-tooltip>
        </ng-container>
        <ng-template #placeholder>
            <shared-placeholder></shared-placeholder>
        </ng-template>
    </div>

    <div class="name-and-info">
        <h3 class="name">{{ product.name }}</h3>
        <div fxLayout="row" fxLayoutAlign="space-between">
            <div class="info">
                <div class="phase-info mat-caption" *ngIf="product.phase === Phase.PhasingOut">
                    <shared-icon icon="info-circle"></shared-icon>
                    <span>
                        {{
                            'product-list.phaseOutWarning'
                                | translate
                                    : {
                                          phaseOutDate: (product.phaseOut | sharedDatePipe)
                                      }
                        }}
                        <ng-container
                            *ngFor="let pid of product.followUpProductIds; last as isLast">
                            <a href="/catalog/product/{{ pid }}">{{ pid }}</a
                            ><span *ngIf="!isLast">, </span>
                        </ng-container>
                    </span>
                </div>

                <div class="phase-info mat-caption" *ngIf="product.phase === Phase.PhasedOut">
                    <shared-icon icon="info-circle"></shared-icon>
                    <span>
                        {{ 'product-list.phaseOutPassed' | translate }}
                        <ng-container
                            *ngFor="let pid of product.followUpProductIds; last as isLast">
                            <a href="/catalog/product/{{ pid }}">{{ pid }}</a
                            ><span *ngIf="!isLast">, </span>
                        </ng-container>
                    </span>
                </div>

                <div class="phase-info mat-caption" *ngIf="product.phase === Phase.PhasingIn">
                    <shared-icon icon="info-circle"></shared-icon>
                    <span>{{
                        'product-list.phaseInInfo'
                            | translate
                                : {
                                      phaseInDate: (product.phaseIn | sharedDatePipe)
                                  }
                    }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="action">
    <ng-content select="[action]"></ng-content>
</div>
