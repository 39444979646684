import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BannerNotificationType } from '@stobag/mystobag-header';
import {
    NewsCategory,
    NewsCategoryIconPipe,
    NewsDTO,
    resolvePlaceHolders,
} from '@stobag/mystobag-notification-shared';
import { CustomDatePipe, MyStobagRedirectService, serviceUrls } from '@stobag/mystobag-shared';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { NewsByCategory } from '../../models/news-by-category';
import { ImportantNoticeBannerService } from '../../services/important-notice-banner.service';
import { NewsService } from '../../services/news.service';

const DEFAULT_BANNER_NOTIFICATION_TYPE = BannerNotificationType.warning;

@Component({
    selector: 'app-news-list',
    templateUrl: './news-list.component.html',
    styleUrls: ['./news-list.component.scss'],
})
export class NewsListComponent implements OnInit {
    NewsCategory = NewsCategory;

    errorMessage: string;
    showError = false;
    newsByCategory$: Observable<NewsByCategory>;

    constructor(
        private redirectService: MyStobagRedirectService,
        private newsService: NewsService,
        private importantNoticeBannerService: ImportantNoticeBannerService,
        private newsCategoryIconPipe: NewsCategoryIconPipe,
        private customDatePipe: CustomDatePipe,
    ) {}

    ngOnInit(): void {
        this.newsService.getNews().subscribe(newsArray => {
            newsArray
                .filter(news => news.showImportantText)
                .map(news => this.resolvePlaceHolders(news))
                .forEach(news => {
                    this.showBanner(news);
                });
        });

        this.newsByCategory$ = this.newsService.getNewsByCategory().pipe(
            tap(nbc => (nbc.NEWS = this.resolvePlaceHolders(nbc.NEWS))),
            catchError((error: HttpErrorResponse) => {
                this.showError = true;
                console.error(error);
                this.errorMessage =
                    error.status === 0
                        ? 'dashboard.unknownError'
                        : 'news-list.NEWS_SERVICE_UNAVAILABLE';
                return throwError(() => error);
            }),
        );
    }

    navigateToNews(uuid: string) {
        if (uuid) {
            this.redirectService.navigateToService(`${serviceUrls.news}/item/${uuid}`);
        }
    }

    private showBanner(news: NewsDTO) {
        const { icon, isSvgIcon } = this.getNewsBannerIcon(news);
        this.importantNoticeBannerService.addNotification(
            news.id,
            news.importantText,
            news.category === NewsCategory.ReleaseNotes
                ? BannerNotificationType.info
                : this.getBannerTypeFromImportanceLevel(news.importanceLevel),
            icon,
            isSvgIcon,
            news.content?.text?.trim().length !== 0,
        );
    }

    /**
     * Returns the icon information that should be displayed in the banner of the news.
     * For "News" category it returns null so the banner default is displayed,
     * for any other it is returning its news category icon.
     * @param news the NewsDTO we want to get the banner icon for
     * @returns icon information with icon name and whether it is an svg icon
     */
    private getNewsBannerIcon(news: NewsDTO): { icon: string; isSvgIcon?: boolean } {
        return NewsCategory.News === news.category
            ? {
                  icon: null,
                  isSvgIcon: false,
              }
            : this.newsCategoryIconPipe.transform(news.category);
    }

    resolvePlaceHolders(news: NewsDTO): NewsDTO {
        return news.placeHolderList?.length > 0
            ? resolvePlaceHolders(news, this.customDatePipe, 'fullDate', 'shortTime')
            : news;
    }

    private getBannerTypeFromImportanceLevel(importanceLevel: NewsDTO['importanceLevel']) {
        if (importanceLevel === 'INFO') {
            return BannerNotificationType.info;
        } else if (importanceLevel === 'ERROR') {
            return BannerNotificationType.error;
        } else if (importanceLevel === 'WARNING') {
            return BannerNotificationType.warning;
        } else {
            return DEFAULT_BANNER_NOTIFICATION_TYPE;
        }
    }
}
