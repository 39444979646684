<div [class.content-container]="isSearchActive">
    <div class="actions">
        <shared-icon
            *ngIf="isSearchActive"
            class="clear-button"
            data-cy="clear-button"
            (click)="onSearchClose()"
            icon="x">
        </shared-icon>
    </div>
    <shared-search-bar
        class="search-bar"
        #searchBar
        [fieldDefs]="searchFieldDefs"
        (filterChange)="onSearchFilterChange($event)"
        (click)="activateSearch()">
    </shared-search-bar>
    <app-search-results
        *ngIf="isSearchActive"
        [searchResponse]="searchResult$ | async"
        [searchFilter]="searchType"
        (containerClientRect)="setBackdropStyle($event)"></app-search-results>
</div>
<div *ngIf="isSearchActive" class="backdrop" data-cy="backdrop" #backdrop></div>
