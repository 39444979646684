<h3 class="white">{{ 'dashboard.header.baskets' | translate }}</h3>
<div
    *ngFor="let basket of baskets.content | slice: 0:resultsToDisplayPerCategory"
    class="basket-container"
    (click)="visitBasket(basket)">
    <shared-table-icon-cell
        icon="shopping-cart"
        [isRowIcon]="true"
        [isOutlined]="true">
    </shared-table-icon-cell>
    <div class="basket-commission">{{ basket.commission }}</div>
    <div class="basket-created-by">{{ basket.orderedBy }}</div>
    <div class="basket-net-price">
        {{ basket.currency ? (basket.totalNetPrice | currency: basket.currency) : '-' }}
    </div>
    <div class="basket-updated">{{ basket.updated | date }}</div>
    <div class="basket-action">
        <button mat-icon-button>
            <shared-icon
                icon="chevron-right"></shared-icon>
        </button>
    </div>
</div>

<app-show-more-button
    *ngIf="displayShowMoreButton"
    [ngPlural]="remainingItemCount"
    (click)="visitBasketList()">
    <ng-template ngPluralCase="=1">{{
        'dashboard.showMoreBasketsSingular' | translate
    }}</ng-template>
    <ng-template ngPluralCase="other">
        {{ 'dashboard.showMoreBasketsPlural' | translate: { remaining: remainingItemCount } }}
    </ng-template>
</app-show-more-button>
