<h3 class="white">{{ 'dashboard.header.orders' | translate }}</h3>
<div
    *ngFor="let order of orders | slice: 0:resultsToDisplayPerCategory"
    class="order-container"
    (click)="visitOrder(order.id)">
    <shared-table-icon-cell
        icon="clipboard-list"
        [isRowIcon]="true"
        [isOutlined]="true">
    </shared-table-icon-cell>
    <div class="order-commission">{{ order.commission }}</div>
    <div class="order-id">{{ order.id }}</div>
    <div class="order-net-price">
        {{ order.netPrice | currency: order.currency:'symbol':'1.2-2':'de-CH' }}
    </div>
    <div class="order-date">{{ order.orderDate | date }}</div>
    <div class="order-action">
        <button mat-icon-button>
            <shared-icon
                icon="chevron-right"></shared-icon>
        </button>
    </div>
</div>

<app-show-more-button
    *ngIf="displayShowMoreButton"
    [ngPlural]="remainingItemCount"
    (click)="visitOrderList()">
    <ng-template ngPluralCase="=1">{{
        'dashboard.showMoreOrdersSingular' | translate
    }}</ng-template>
    <ng-template ngPluralCase="other">
        {{ 'dashboard.showMoreOrdersPlural' | translate: { remaining: remainingItemCount } }}
    </ng-template>
</app-show-more-button>
