import { Injectable } from '@angular/core';
import { BannerNotificationType, BannerService } from '@stobag/mystobag-header';
import { MyStobagRedirectService, serviceUrls } from '@stobag/mystobag-shared';

@Injectable()
export class ImportantNoticeBannerService {
    private blacklistedNotifications: Record<string, number>;

    constructor(
        private bannerService: BannerService,
        private redirectService: MyStobagRedirectService,
    ) {
        this.blacklistedNotifications = this.getSavedNotificationsFromLocalStorage();
    }

    addNotification(
        id: string,
        message: string,
        type: BannerNotificationType,
        icon: string,
        isSvgIcon?: boolean,
        hasContent = false,
    ) {
        if (this.isBlacklisted(id)) {
            return;
        }

        const links = hasContent
            ? [
                  {
                      label: 'news-list.readMore',
                      onClick: () =>
                          this.redirectService.navigateToService(`${serviceUrls.news}/item/${id}`),
                  },
              ]
            : [];

        this.bannerService.addNotification({
            id,
            message,
            type,
            removeOnNavigation: true,
            closeable: true,
            onRemove: () => this.addToBlackList(id),
            links,
            icon,
            isSvgIcon,
        });
    }

    private isBlacklisted(notificationId) {
        const isIdBlacklisted = Object.keys(this.blacklistedNotifications).includes(notificationId);
        const isIn24Hours = this.isIn24Hours(this.blacklistedNotifications[notificationId]);
        return isIdBlacklisted && isIn24Hours;
    }

    private isIn24Hours(timestamp: number): boolean {
        const now = new Date().getTime();
        const oneDay = 24 * 60 * 60 * 1000;
        return now < timestamp + oneDay;
    }

    private addToBlackList(notificationId: string): void {
        this.blacklistedNotifications = {
            ...this.blacklistedNotifications,
            [notificationId]: new Date().getTime(),
        };
        this.saveNotificationsInLocalStorage(this.blacklistedNotifications);
    }

    private getSavedNotificationsFromLocalStorage(): Record<string, number> {
        try {
            const stringifiedNotifications = localStorage.getItem('notificationBlacklist');
            const blacklistedNotifications = JSON.parse(stringifiedNotifications);
            return blacklistedNotifications ?? {};
        } catch {
            return {};
        }
    }

    private saveNotificationsInLocalStorage(
        blacklistedNotifications: Record<string, number>,
    ): void {
        const stringifiedNotifications = JSON.stringify(blacklistedNotifications);
        localStorage.setItem('notificationBlacklist', stringifiedNotifications);
    }
}
