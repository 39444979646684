import { HTTP_INTERCEPTORS, HttpBackend, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
    CONFIG_SERVICE_CONFIGURATION,
    ConfigService,
    ConfigServiceConfiguration,
    GlobalErrorHandler,
    HttpInterceptorService,
    initialize,
} from '@stobag/mystobag-shared';
import { NgxPermissionsModule } from 'ngx-permissions';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HealthCheckService } from './health-check/health-check.service';
import { HealthCheckAlertDirective } from './health-check/health-check-alert.directive';
import { translateLoaderFactory } from './modules/core/util/translate-loader-factory';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { SearchModule } from './modules/search/search.module';
import { SharedModule } from './modules/shared/shared.module';

@NgModule({
    declarations: [AppComponent, HealthCheckAlertDirective],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateLoaderFactory,
                deps: [HttpBackend, ConfigService],
            },
        }),
        NgxPermissionsModule.forRoot(),
        DashboardModule,
        SearchModule,
        SharedModule,
        AppRoutingModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        ConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: initialize,
            deps: [ConfigService],
            multi: true,
        },
        {
            provide: CONFIG_SERVICE_CONFIGURATION,
            useFactory: () => {
                const configuration: Partial<ConfigServiceConfiguration> = {
                    serviceName: environment.serviceName,
                    verifyToken: true,
                };
                return configuration;
            },
        },
        HealthCheckService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
