<h3 class="white">{{ 'dashboard.header.offers' | translate }}</h3>
<div
    *ngFor="let offer of offers | slice: 0:resultsToDisplayPerCategory"
    class="offer-container"
    (click)="visitOrder(offer.id)">
    <shared-table-icon-cell
        icon="clipboard-list"
        [isRowIcon]="true"
        [isOutlined]="true">
    </shared-table-icon-cell>
    <div class="offer-commission">{{ offer.commission }}</div>
    <div class="offer-id">{{ offer.id }}</div>
    <div class="offer-net-price">
        {{ offer.netPrice | currency: offer.currency:'symbol':'1.2-2':'de-CH' }}
    </div>
    <div class="offer-date">{{ offer.offerDate | date }}</div>
    <div class="offer-action">
        <button mat-icon-button>
            <shared-icon
                icon="chevron-right"></shared-icon>
        </button>
    </div>
</div>

<app-show-more-button
    *ngIf="displayShowMoreButton"
    [ngPlural]="remainingItemCount"
    (click)="visitOrderList()">
    <ng-template ngPluralCase="=1">{{
        'dashboard.showMoreOffersSingular' | translate
    }}</ng-template>
    <ng-template ngPluralCase="other">
        {{ 'dashboard.showMoreOffersPlural' | translate: { remaining: remainingItemCount } }}
    </ng-template>
</app-show-more-button>
