import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BasicLayoutComponent } from '@stobag/mystobag-header';
import { AccessAndRoleGuardService } from '@stobag/mystobag-shared';

import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { TrainingCenterCategoriesResolver } from './modules/training-center/resolvers/training-center-categories.resolver';

const routes: Routes = [
    { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
    {
        path: '',
        component: BasicLayoutComponent,
        children: [
            {
                path: 'dashboard',
                component: DashboardComponent,
                canActivate: [AccessAndRoleGuardService],
                data: {
                    access: 'ACCESS_SERVICE_DASHBOARD',
                },
            },
        ],
    },
    {
        path: 'training-center',
        loadChildren: () =>
            import('./modules/training-center/training-center.module').then(
                m => m.TrainingCenterModule,
            ),
        canActivate: [AccessAndRoleGuardService],
        data: {
            access: 'ACCESS_SERVICE_TRAINING_CENTER',
        },
        resolve: { categories: TrainingCenterCategoriesResolver },
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
