import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BasketFilterParams, OrderStatus } from '@stobag/mystobag-basket-shared';
import { MyStobagSubheaderService, PageNameEvent } from '@stobag/mystobag-header';
import { OfferFilter } from '@stobag/mystobag-order-shared';
import {
    AuthenticationService,
    BrowserCheckService,
    ConfigService,
    headerLinks,
    MyStobagRedirectService,
    serviceUrls,
} from '@stobag/mystobag-shared';
import { LeadApiService } from '@stobag/partnernet-lead-shared';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

import { BrowserCheckPopupComponent } from './components/browser-check-popup/browser-check-popup.component';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, AfterViewInit {
    @ViewChild('dashboardContent') dashboardContentRef: ElementRef;

    popupDisplayFrequency = 1;
    basketLimit: number;
    orderLoadError = false;
    offerLoadError = false;
    showLeads$: Observable<boolean>;
    emptyCardSize = 'small';

    readonly openBasketsFilter: BasketFilterParams = {
        status: [OrderStatus.Open],
    };

    readonly openOffersFilter: OfferFilter = {
        searchKey: null,
        status: ['OPEN'],
    };

    constructor(
        private redirectService: MyStobagRedirectService,
        private subheaderService: MyStobagSubheaderService,
        private configService: ConfigService,
        private cookieService: CookieService,
        private dialog: MatDialog,
        private browserCheckService: BrowserCheckService,
        private authentication: AuthenticationService,
        leadService: LeadApiService,
        private httpClient: HttpClient,
    ) {
        this.basketLimit = this.configService.getConfig().basketLimit;
        this.popupDisplayFrequency = this.configService.getConfig().popupDisplayFrequency || 1;
        this.showLeads$ = leadService.hasAnyLeads();
    }

    ngOnInit() {
        this.subheaderService.updatePageNameByEvent({
            pageName: 'shared.welcomeUser',
            pageNameDetail: ` ${this.authentication.getNameAndSurnameOfUser()}`,
            detail: headerLinks.dashboard.displayName,
        } as PageNameEvent);
    }

    ngAfterViewInit() {
        const popupCookie = this.cookieService.get('popup');
        const unsupportedBrowser = !this.browserCheckService.isBrowserSupported();
        if (!popupCookie && unsupportedBrowser) {
            this.dialog
                .open(BrowserCheckPopupComponent, { disableClose: true })
                .afterClosed()
                .subscribe(() => {
                    this.cookieService.set(
                        'popup',
                        new Date().toJSON(),
                        this.popupDisplayFrequency,
                    );
                });
        }
        this.subheaderService.updatePageNameByEvent({
            pageName: 'shared.navigation.dashboard',
        } as PageNameEvent);
        this.subheaderService.hideBackButton();
    }

    navigateToOrderService() {
        this.redirectService.navigateToService(serviceUrls.order);
    }

    navigateToOfferService() {
        this.redirectService.navigateToService(serviceUrls.offer);
    }

    navigateToLeadService() {
        this.redirectService.navigateToService(serviceUrls.lead);
    }

    navigateToBasketService() {
        this.redirectService.navigateToService(serviceUrls.basket);
    }

    navigateToNewsList() {
        this.redirectService.navigateToService(serviceUrls.news);
    }

    onOrderLoadError(_) {
        this.orderLoadError = true;
    }

    onOfferLoadError(_) {
        this.offerLoadError = true;
    }
}
