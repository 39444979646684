import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { OrderDTO } from '@stobag/mystobag-order-shared';
import { MyStobagRedirectService, serviceUrls } from '@stobag/mystobag-shared';
import { Subscription } from 'rxjs';
import { resultsToDisplayPerCategory } from 'src/app/constants';

@Component({
    selector: 'app-order-results',
    templateUrl: './order-results.component.html',
    styleUrls: ['./order-results.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderResultsComponent implements OnInit, OnDestroy {
    resultsToDisplayPerCategory = resultsToDisplayPerCategory;
    displayShowMoreButton = false;
    remainingItemCount: number;

    @Input() orders: OrderDTO[];
    @Input() searchKeyword: string;

    private subscription = new Subscription();

    constructor(private redirectService: MyStobagRedirectService) {}

    ngOnInit() {
        this.remainingItemCount = this.orders.length - resultsToDisplayPerCategory;
        this.displayShowMoreButton = this.remainingItemCount > 0;
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    visitOrder(orderId: string) {
        this.redirectService.navigateToService(`${serviceUrls.order}/${orderId}`);
    }

    visitOrderList() {
        this.redirectService.navigateToService(`${serviceUrls.order}?search=${this.searchKeyword}`);
    }
}
