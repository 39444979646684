import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NewsDTO } from '@stobag/mystobag-notification-shared';
import { AuthenticationService, LoadingSpinnerService } from '@stobag/mystobag-shared';
import { Observable } from 'rxjs';

import { NewsByCategory } from '../models/news-by-category';

@Injectable()
export class NewsService {
    newsUrl = '/notification/api/news';

    constructor(
        private httpClient: HttpClient,
        private authenticationService: AuthenticationService,
        private loadingSpinnerService: LoadingSpinnerService,
    ) {}

    getUserLocale(): string {
        return this.authenticationService.extractLocale();
    }

    getNews(): Observable<NewsDTO[]> {
        return this.loadingSpinnerService.withLoader(
            this.httpClient.get<NewsDTO[]>(`${this.newsUrl}`),
            'news-list',
        );
    }

    getNewsByCategory(): Observable<NewsByCategory> {
        return this.loadingSpinnerService.withLoader(
            this.httpClient.get<NewsByCategory>(`${this.newsUrl}/by-category`),
            'news-list',
        );
    }
}
