<mat-card *ngIf="news; else noNewsInThisCategory">
    <div>{{ 'news-list.' + news.category | translate }}</div>
    <div class="card-content">
        <div *ngIf="news.category | newsCategoryIcon as newsIcon" class="news-icon">
            <shared-icon
                [icon]="newsIcon.icon"
                [isSvgIcon]="newsIcon.isSvgIcon"
                [class.svg-icon]="newsIcon.isSvgIcon"
            ></shared-icon>
        </div>
        <div>
            <h4 class="news-title div-wrap">{{ news.title }}</h4>
            <div id="validFrom{{ news.title }}" class="mat-caption">
                {{ news.validFrom | date: 'EEEE, dd.MM.yyyy' }}
            </div>
        </div>
    </div>
</mat-card>

<ng-template #noNewsInThisCategory>
    <shared-empty-table-card
        cardSize="small"
        [icon]="emptyIcon"
        [isSvgIcon]="true"
        [label]="'news-list.noNewsInCategory' | translate"></shared-empty-table-card>
</ng-template>
