import { HttpBackend } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NewsCategoryIconPipe } from '@stobag/mystobag-notification-shared';
import { ConfigService } from '@stobag/mystobag-shared';
import { PartnernetLeadSharedModule } from '@stobag/partnernet-lead-shared';
import { NgxPermissionsModule } from 'ngx-permissions';

import { CoreModule } from '../core/core.module';
import { translateLoaderFactory } from '../core/util/translate-loader-factory';
import { SearchModule } from '../search/search.module';
import { SharedModule } from '../shared/shared.module';
import { BrowserCheckPopupComponent } from './components/browser-check-popup/browser-check-popup.component';
import { LinkSectionComponent } from './components/link-section/link-section.component';
import { NewsCardComponent } from './components/news-list/news-card/news-card.component';
import { NewsListComponent } from './components/news-list/news-list.component';
import { DashboardComponent } from './dashboard.component';
import { ClickStopPropagationDirective } from './directives/click-stop-propagation/click-stop-propagation.directive';
import { ImportantNoticeBannerService } from './services/important-notice-banner.service';
import { NewsService } from './services/news.service';

@NgModule({
    declarations: [
        DashboardComponent,
        NewsListComponent,
        LinkSectionComponent,
        BrowserCheckPopupComponent,
        NewsCardComponent,
        ClickStopPropagationDirective,
    ],
    providers: [NewsService, ImportantNoticeBannerService, NewsCategoryIconPipe],
    imports: [
        CoreModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: translateLoaderFactory,
                deps: [HttpBackend, ConfigService],
            },
        }),
        NgxPermissionsModule.forChild(),
        SharedModule,
        SearchModule,
        PartnernetLeadSharedModule,
    ],
    exports: [DashboardComponent],
})
export class DashboardModule {}
