import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '@stobag/mystobag-shared';
import { BehaviorSubject } from 'rxjs';

import { TrainingCenterCategory } from '../../training-center/models/training-center-category';

export interface Tab {
    label: string;
    url: string;
}

@Injectable({
    providedIn: 'root',
})
export class TrainingCenterService {
    private _tabs = new BehaviorSubject<Tab[]>([]);
    tabs$ = this._tabs.asObservable();

    private _currentTab = new BehaviorSubject<string | null>(null);
    currentTab$ = this._currentTab.asObservable();

    lastContentScrollTop: number;

    constructor(private configService: ConfigService) {}

    initTabs(categories: TrainingCenterCategory[]): void {
        const categoryTabs = this.mapCategoriesToTabs(categories);
        const additionalTabs = this.getAdditionalTrainingCenterTabs();
        this._tabs.next([...categoryTabs, ...additionalTabs]);
    }

    setCurrentTab(link: string): void {
        this._currentTab.next(link);
    }

    private mapCategoriesToTabs(categories: TrainingCenterCategory[]): Tab[] {
        return categories.map(category => {
            const { title, tabUrl, id } = category;
            return {
                id,
                label: title,
                url: tabUrl,
            };
        });
    }

    private getAdditionalTrainingCenterTabs(): Tab[] {
        const additionalTabs: Tab[] = [];

        additionalTabs.push(
            {
                label: 'training-center.videoTutorials',
                url: 'video-tutorial',
            },
            {
                label: 'training-center.workshops',
                url: 'workshops',
            },
        );
        return additionalTabs;
    }
}
