<h3 class="white">{{ 'dashboard.header.serviceReports' | translate }}</h3>
<div
    *ngFor="let serviceReport of serviceReports | slice: 0:resultsToDisplayPerCategory"
    class="service-report-container"
    (click)="visitServiceReport(serviceReport)"
    data-cy="service-report-container">
    <shared-table-icon-cell
        icon="tool"
        [isRowIcon]="true"
        [isOutlined]="true">
    </shared-table-icon-cell>
    <div class="service-report-id">{{ getIdentifier(serviceReport) }}</div>
    <div class="service-report-commission">{{ serviceReport.commission }}</div>
    <div class="service-report-damage">{{ serviceReport.damage }}</div>
    <div class="service-report-date">{{ serviceReport.createdDate | date }}</div>
    <div class="service-report-action">
        <button mat-icon-button>
            <shared-icon
                icon="chevron-right"></shared-icon>
        </button>
    </div>
</div>

<app-show-more-button
    *ngIf="displayShowMoreButton"
    [ngPlural]="remainingItemCount"
    (click)="visitServiceReportList()">
    <ng-template ngPluralCase="=1">{{
        'dashboard.showMoreServiceReportsSingular' | translate
    }}</ng-template>
    <ng-template ngPluralCase="other">
        {{
            'dashboard.showMoreServiceReportsPlural' | translate: { remaining: remainingItemCount }
        }}
    </ng-template>
</app-show-more-button>
