import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, SnackbarType } from '@stobag/mystobag-shared';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { TrainingCenterCategory } from '../models/training-center-category';
import { TrainingCenterService } from '../services/training-center.service';
import { TrainingCenterApiService } from '../services/training-center-api.service';

@Injectable({
    providedIn: 'root',
})
export class TrainingCenterCategoriesResolver implements Resolve<TrainingCenterCategory[]> {
    constructor(
        private trainingCenterApiService: TrainingCenterApiService,
        private trainingCenterService: TrainingCenterService,
        private dialogService: DialogService,
        private translateService: TranslateService,
    ) {}

    resolve(): Observable<TrainingCenterCategory[]> {
        return this.trainingCenterApiService.getCategories().pipe(
            catchError(() => {
                this.showErrorSnackbar();
                return of([]);
            }),
            tap(categories => this.trainingCenterService.initTabs(categories)),
        );
    }

    private showErrorSnackbar() {
        const message = this.translateService.instant('training-center.loadError');
        this.dialogService.openSnackbar(message, SnackbarType.ERROR, 5);
    }
}
