import { HttpBackend } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AuthenticationService, ConfigService } from '@stobag/mystobag-shared';

import { CoreModule } from '../core/core.module';
import { translateLoaderFactory } from '../core/util/translate-loader-factory';
import { SharedModule } from '../shared/shared.module';
import { BasketResultsComponent } from './components/search-results/basket-results/basket-results.component';
import { DocumentResultsComponent } from './components/search-results/document-results/document-results.component';
import { EquipmentCardComponent } from './components/search-results/equipment-results/equipment-card/equipment-card.component';
import { EquipmentResultsComponent } from './components/search-results/equipment-results/equipment-results.component';
import { OfferResultsComponent } from './components/search-results/offer-results/offer-results.component';
import { OrderResultsComponent } from './components/search-results/order-results/order-results.component';
import { ProductResultCardComponent } from './components/search-results/product-results/product-result-card/product-result-card.component';
import { ProductResultsComponent } from './components/search-results/product-results/product-results.component';
import { SearchResultsComponent } from './components/search-results/search-results.component';
import { ServiceReportResultsComponent } from './components/search-results/service-report-results/service-report-results.component';
import { ShowMoreButtonComponent } from './components/search-results/show-more-button/show-more-button.component';
import { SparePartResultsComponent } from './components/search-results/spare-part-results/spare-part-results.component';
import { SearchComponent } from './search.component';
import { SearchService } from './services/search.service';

@NgModule({
    declarations: [
        SearchComponent,
        SearchResultsComponent,
        ProductResultsComponent,
        SparePartResultsComponent,
        BasketResultsComponent,
        DocumentResultsComponent,
        OrderResultsComponent,
        ServiceReportResultsComponent,
        EquipmentResultsComponent,
        ShowMoreButtonComponent,
        ProductResultCardComponent,
        EquipmentCardComponent,
        OfferResultsComponent,
    ],
    imports: [
        CoreModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: translateLoaderFactory,
                deps: [HttpBackend, ConfigService],
            },
        }),
        SharedModule,
    ],
    providers: [AuthenticationService, SearchService],
    exports: [SearchComponent],
})
export class SearchModule {}
