import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DocumentDTO, DocumentReadService } from '@stobag/mystobag-document-shared';
import { DialogService, MyStobagRedirectService, serviceUrls } from '@stobag/mystobag-shared';
import { Subscription } from 'rxjs';
import { resultsToDisplayPerCategory } from 'src/app/constants';

@Component({
    selector: 'app-document-results',
    templateUrl: './document-results.component.html',
    styleUrls: ['./document-results.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentResultsComponent implements OnInit, OnDestroy {
    resultsToDisplayPerCategory = resultsToDisplayPerCategory;
    displayShowMoreButton = false;
    remainingItemCount: number;

    @Input() documents: DocumentDTO[];
    @Input() searchKeyword: string;

    private subscription = new Subscription();

    constructor(
        private redirectService: MyStobagRedirectService,
        private documentService: DocumentReadService,
        private dialogService: DialogService,
    ) {}

    ngOnInit() {
        this.remainingItemCount = this.documents.length - resultsToDisplayPerCategory;
        this.displayShowMoreButton = this.remainingItemCount > 0;
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    downloadDocument(documentDTO: DocumentDTO) {
        if (documentDTO.contentType === 'VIDEO') {
            this.watchVideo(documentDTO);
        } else {
            window.open(documentDTO.url, '_blank');
        }
    }

    watchVideo(documentDTO: DocumentDTO) {
        this.dialogService.openVideoPlayer({
            url: documentDTO.url,
            title: documentDTO.displayedName,
            playAutomatically: true,
        });
    }

    visitDocumentList() {
        this.redirectService.navigateToService(
            `${serviceUrls.document}?search=${this.searchKeyword}`,
        );
    }
}
