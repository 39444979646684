<div class="page-container relative">
    <shared-subheader [extended]="true" class="with-search-bar"></shared-subheader>
    <div class="content-container">
        <app-search [dashboardContentRef]="dashboardContentRef"></app-search>
        <h3 class="subtitle">{{ 'dashboard.subtitle' | translate }}</h3>
        <div class="dashboard-content" #dashboardContent>
            <div class="full-width">
                <app-link-section></app-link-section>
            </div>

            <ng-container *ngIf="showLeads$ | async">
                <div
                    class="full-width"
                    *ngxPermissionsOnly="['ACCESS_SERVICE_LEAD']"
                    data-cy="dashboard-lead-section">
                    <div fxLayout="row" fxLayoutAlign="space-between center" class="title-row">
                        <h3>{{ 'lead.tableTitle' | translate }}</h3>
                        <div
                            id="seeMoreLeads"
                            class="see-all-link"
                            (click)="navigateToLeadService()">
                            <div>{{ 'lead.linkToAllItems' | translate }}</div>
                            <shared-icon
                                class="see-all-arrow"
                                icon="arrow-narrow-right"></shared-icon>
                        </div>
                    </div>
                    <lead-lead-compact-list
                        [openLeadFromExternalService]="true"
                        [emptyCardSize]="emptyCardSize"
                        data-cy="lead-table-component"></lead-lead-compact-list>
                </div>
            </ng-container>

            <div
                class="full-width"
                *ngxPermissionsOnly="['ACCESS_SERVICE_BASKET']"
                data-cy="dashboard-basket-section">
                <div fxLayout="row" fxLayoutAlign="space-between center" class="title-row">
                    <h3>{{ 'basket-list.title' | translate }}</h3>
                    <div
                        id="seeMoreOpenOrders"
                        class="see-all-link"
                        (click)="navigateToBasketService()">
                        <span>{{ 'basket-list.linkToAllItems' | translate }}</span>
                        <shared-icon class="see-all-arrow" icon="arrow-narrow-right"></shared-icon>
                    </div>
                </div>
                <basket-basket-list
                    [hidePaginator]="true"
                    [openBasketFromExternalService]="true"
                    [filterParams]="openBasketsFilter"
                    [showTableIcon]="true"
                    [limit]="basketLimit"
                    [emptyCardSize]="emptyCardSize"
                    data-cy="basket-table-component"></basket-basket-list>
            </div>

            <div
                class="full-width"
                *ngxPermissionsOnly="['ACCESS_SERVICE_OFFER']"
                data-cy="dashboard-offer-section">
                <div fxLayout="row" fxLayoutAlign="space-between center" class="title-row">
                    <h3>{{ 'offer-list.title' | translate }}</h3>
                    <div id="seeMoreOffers" class="see-all-link" (click)="navigateToOfferService()">
                        <span>{{ 'offer-list.linkToAllItems' | translate }}</span>
                        <shared-icon class="see-all-arrow" icon="arrow-narrow-right"></shared-icon>
                    </div>
                </div>
                <order-offer-list
                    [hidePaginator]="true"
                    [showTableIcon]="true"
                    limit="6"
                    [filter]="openOffersFilter"
                    [openOfferFromExternalService]="true"
                    (offerLoadError)="onOfferLoadError($event)"
                    [emptyCardSize]="emptyCardSize"
                    data-cy="offer-table-component"></order-offer-list>
            </div>

            <div
                class="full-width"
                *ngxPermissionsOnly="['ACCESS_SERVICE_ORDER']"
                data-cy="dashboard-order-section">
                <div fxLayout="row" fxLayoutAlign="space-between center" class="title-row">
                    <h3>{{ 'order-list.title' | translate }}</h3>
                    <div id="seeMoreOrders" class="see-all-link" (click)="navigateToOrderService()">
                        <span>{{ 'order-list.linkToAllItems' | translate }}</span>
                        <shared-icon class="see-all-arrow" icon="arrow-narrow-right"></shared-icon>
                    </div>
                </div>
                <order-order-list
                    [hidePaginator]="true"
                    [showTableIcon]="true"
                    limit="6"
                    [openOrderFromExternalService]="true"
                    (orderLoadError)="onOrderLoadError($event)"
                    [emptyCardSize]="emptyCardSize"
                    data-cy="order-table-component"></order-order-list>
            </div>

            <div
                class="full-width"
                *ngxPermissionsOnly="['ACCESS_SERVICE_NEWS']"
                data-cy="dashboard-news-section">
                <div fxLayout="row" fxLayoutAlign="space-between center" class="title-row">
                    <h3>{{ 'news-list.title' | translate }}</h3>
                    <div id="seeMoreNews" class="see-all-link" (click)="navigateToNewsList()">
                        <span>{{ 'news-list.linkToAllItems' | translate }}</span>
                        <shared-icon class="see-all-arrow" icon="arrow-narrow-right"></shared-icon>
                    </div>
                </div>
                <app-news-list></app-news-list>
            </div>
        </div>
    </div>
</div>
